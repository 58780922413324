$cl-white: white;
$cl-white--darker: #F7F7FA;

$cl-black: black;
$cl-black--light: #040925;
$cl-black--lighter: #0E132C;
$cl-black--lightest: #24283D;

$cl-grey--darkest: #4E515E;
$cl-grey--darker: #6C6F7B;
$cl-grey--dark: #888B97;
$cl-grey: #A5A7B0;
$cl-grey--light: #BABCC3;
$cl-grey--lighter: #D0D2DA;
$cl-grey--lightest: #ECEDF2;
$cl-grey--border: #E6E7E8;

$cl-primary: #F5885E;
$cl-primary--light: #FC9D78;
$cl-primary--lighter: #FFDBBF;
$cl-primary--lightest:#FFEFE0;

$cl-secondary--darker: #111A49;
$cl-secondary--dark: #2D3665;
$cl-secondary: #1687F1;
$cl-secondary--light: #C7E4FF;
$cl-secondary--lighter: #E2F6FF;

$cl-error: #d14;
$cl-success: #19a974;
$cl-warning: #F5885E;

$cl-shadow: #090c18;
