@import "colors";
@import "mixins";
@import "variables";
@import "functions";

@mixin start($amount) {
  grid-column-start: $amount;
}

@mixin col($colIndex) {
  grid-column-end: span $colIndex;
}

.grid {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr);
  column-gap: $grid-gap;

  > * {
    grid-column-end: span 12;
  }

  @include max(m) {
    column-gap: $grid-gap--mobile;
  }
}

.grid--row-gap {
  row-gap: 3.2rem;
}

@for $colIndex from 1 through $grid-columns {
  body .col#{$colIndex} {
    grid-column-end: span $colIndex;

    .flexgrid & {
      width: calc(#{percentage($colIndex / $grid-columns)} - #{$grid-gap});

      @include max(m) {
        width: calc(#{percentage($colIndex / $grid-columns)} - #{$grid-gap--mobile});
      }
    }
  }

  body .push#{$colIndex} {
    grid-column-start: $colIndex + 1;
  }

  @each $bp, $size in $breakpoints {
    @include min($bp) {
      body .col-#{$bp}#{$colIndex} {
        grid-column-end: span $colIndex;
      }

      body .push-#{$bp}#{$colIndex} {
        grid-column-start: $colIndex + 1;
      }
    }
  }
}

.container {
  max-width: $max-container-width + parseInt($gutter) * 10px;
  width: 100%;
  margin: 0 auto;
  padding: 0 $gutter / 2;
}

.group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.group--centered {
  justify-content: center;
}

.page-intro {
  margin-bottom: 3.2rem;
  display: flex;
  height: 5rem;
  flex-direction: column;
  justify-content: space-between;
}

.page-section {
  margin-bottom: 4.8rem;
  line-height: 1.75;
  text-align: justify;

  h5 {
    margin-bottom: 1.6rem;
  }

  p,
  ul {
    margin: 1.6rem 0;
  }

  li {
    margin: 0 0 0.8rem 1.6rem;
  }
}

.section-cards {
  @extend .grid;
  margin: 0 0 6.4rem;
  padding: 0;
  list-style: none;

  @include max(m) {
    row-gap: 1.6rem;
    margin-bottom: 4rem;
  }
}

.section-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0.8rem 0.8rem 2.4rem;
  box-shadow: 0 0 0 0.1rem $cl-grey--lightest;
  border-radius: 0.6rem;
}

.section-card--secondary {
  .section-card__header {
    background-color: $cl-secondary--lighter;
  }
}

.section-card__header {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5.6rem;
  background-color: $cl-primary--lightest;
  border-radius: 0.4rem;
}

.section-card__avatar {
  flex-shrink: 0;
  width: 10.4rem;
  height: 10.4rem;
  margin-top: 3.2rem;
  margin-bottom: -4rem;
  box-shadow: 0 0 0 0.8rem $cl-white;
  border-radius: 50%;
}

.section-card__heading {
  margin-top: 0.8rem;
  text-align: center;
}

.section-card__subtitle {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.5;
}

.image {
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.image-wrapper {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  box-sizing: border-box;
  margin: 0;

  img {
    visibility: visible;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: medium none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
