@mixin font-size($xs: null, $sm: null, $md: null, $lg: null) {
  font-size: #{$xs};

  @if $sm {
    @include min(s) {
      font-size: #{$sm}
    }
  }

  @if $md {
    @include min(m) {
      font-size: #{$md}
    }
  }

  @if $lg {
    @include min(l) {
      font-size: #{$lg}
    }
  }
}

@mixin min($size) {
  $breakpoint: map-get($breakpoints, $size);

  @media (min-width: $breakpoint + 1) {
    @content;
  }
}

@mixin max($size) {
  $breakpoint: map-get($breakpoints, $size);

  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin range($sizeMin, $sizeMax) {
  $breakpointMin: map-get($breakpoints, $sizeMin);
  $breakpointMax: map-get($breakpoints, $sizeMax);

  @media (min-width: $breakpointMin + 1) and (max-width: $breakpointMax) {
    @content;
  }
}
