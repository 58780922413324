@import 'styles/mixins';
@import 'styles/variables';

.page-container {
  align-items: center;
  flex-grow: 1;
  width: 100%;

  > * {
    // animation: fadeIn 200ms both;
  }

  &:not(.page-container--dashboard) {
    @include max(s) {
      margin-top: 6.5rem;
    }
  }
}

@keyframes fadeIn {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
