$breakpoints: (
  "xs": 375px,
  "s": 720px,
  "m": 1024px,
  "l": 1120px,
  "xl": 1240px,
  "xxl": 1440px,
);

$gutter:                      3.2rem !default;
$grid-columns:                12;
$grid-gap:                    $gutter;
$grid-gap--mobile:            3.2rem / 2;

$max-container-width:         1120px !default;

$spacing:                     ($gutter / 2) !default;

$font-name:                   'Circular';
$font-body:                   $font-name, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-weight--book:           400;
$font-weight--medium:         500;
$font-weight--bold:           700;
$font-weight--black:          900;

$zIndex--bottom:          0;
$zIndex--default:         1;
$zIndex--middle:          2;
$zIndex--modal:           10;

// APP
$app-header-height:           8rem;
$app-header-height--mobile:   6.4rem;
