@import "../../../styles/variables";
@import "../../../styles/grid";

.mobile {
  display: none;

  > div:first-child {
    margin-bottom: 3.2rem;

    h3 {
      font-size: 3.2rem;
    }

    h3 + div {
      font-size: 2rem;
    }
  }

  @include max(m) {
    display: block;
  }

  :global .swiper-container {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    width: calc(100% + 3.2rem);

    .swiper-slide {
      padding: 0 1.6rem;
    }
  }
}

.desktop {
  @include max(m) {
    display: none;
  }
}
